import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Countdown",
  code: "Countdown"
};
export const _frontmatter = {
  "menuLabel": "Countdown",
  "sortOrder": 3.4
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Countdown`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-countdown--from-date" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Countdown } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`Countdown`}</inlineCode>{` component is a timer that can count down from a date, timestamp or number.  Can be useful for showing the remaining time left for an event.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
   The Countdown component has a clear visual display that is easily noticeable and readable, using appropriate color contrast and font size. It also provides alternative text or aria-label for screen readers to announce the remaining time.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    Although the Countdown Timer is not directly operable by the user, it is designed to be keyboard accessible, allowing users to focus on the timer and navigate to other interactive elements on the page while the countdown is in progress.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The Countdown component's purpose and functionality are clearly communicated through clear labels and intuitive design. Users can easily comprehend that it represents a countdown and understand the remaining time. The behavior of the automatic countdown is predictable.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The component is compatible with assistive technologies, ensuring that it can be accessed and used by users who rely on screen readers or other accessibility tools. It also functions consistently across different browsers and platforms.
    </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      